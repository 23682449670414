<template>
  <v-app>
    <Navigation :color="color" :flat="flat" />
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-scale-transition>
      <v-btn
        fab
        v-show="fab"
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <Footer />
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

export default {
  name: "App",

  components: {
    Navigation,
    Footer,
  },

  data: () => ({
    fab: null,
    color: "#4C6663",
    flat: null,
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "#4C6663";
      this.flat = true;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "#4C6663";
        this.flat = false;
      } else {
        this.color = "#4C6663";
        this.flat = true;
      }
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
<style scoped>
.v-main {
  background-image: url("~@/assets/mainBg.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
</style>
<style lang="scss">
  // @font-face {
  //   font-family: "Yanone Kaffeesatz";
  //   src: url("../fonts/YanoneKaffeesatz-Regular.ttf");
  // }
</style>
