<template>
  <div>
    <HomeSection />
    <AboutProductSection />
    <GeneralSection />
    <ValuesSection />
  </div>
</template>

<script>
import HomeSection from "@/components/HomeSection";
import AboutProductSection from "@/components/AboutProductSection";
import GeneralSection from "@/components/GeneralSection";
import ValuesSection from "@/components/ValuesSection";


export default {
  name: "Home",
  components: {
    HomeSection,
    AboutProductSection,
    GeneralSection,
    ValuesSection,
  },
};
</script>
