<template>
  <v-footer dark padless color="#4C6663">
    <v-card flat tile class="white--text text-center" color="#4C6663">
      <v-card-text>
        <span class="white--text pt-0">
          Get the latest updates by subscribing to our Instagram. Stay informed
          about products, exclusive previews, and more. Join our community and
          be the first to know about important announcements.
        </span>
        <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="mx-4 white--text"
          :href="icon.link"
          target="_blank"
          icon
        >
          <v-icon size="24px">{{ icon.icon }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        We would like to acknowledge that the land on which we are operating is
        unceded traditional territories of the xʷməθkʷəy̓əm (Musqueam),
        Sḵwx̱wú7mesh (Squamish), and səlilwətaɬ (Tsleil-Waututh) Nations.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>the Scentology</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
export default {
  data: () => ({
    icons: [
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/thescentologyofficial",
        text: "Get the latest updates by subscribing to our Instagram. Stay informed about products, exclusive previews, and more. Join our community and be the first to know about important announcements. ",
      },
    ],
  }),
};
</script>
