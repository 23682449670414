<template>
  <section id="homeSection">
    <div id="homeParallax" class="row align-center justify-center">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="4" xl="3" class="home-content">
              <h1 class="display-2 font-weight-bold mb-4">the Scentology</h1>
              <div>
                <p class="font-weight-light text-h5">
                  Discover the healing touch of 100% natural essential oils.
                  Wellness products made locally in an eco-friendly way
                </p>
              </div>
              <v-btn
                rounded
                outlined
                large
                dark
                @click="$vuetify.goTo('#aboutProduct')"
                class="mt-5"
              >
                Learn More
                <v-icon class="ml-2">mdi-arrow-down</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="8" xl="9" class="hidden-sm-and-down"> </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="svg-border-waves text-white">
        <v-img src="@/assets/borderWaves.svg" />
      </div>
    </div>
    <v-container fluid id="aboutProduct" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(value, i) in companyValues"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="value.img"
                    max-width="120px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular">{{ value.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ value.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-waves">
      <img src="~@/assets/wave2.svg" />
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeSection",
  components: {},
  data() {
    return {
      companyValues: [
        {
          img: require("@/assets/natural_excellence.png"),
          title: "Natural Excellence",
          text: "We uphold the highest standard of natural excellence. When we say 100% natural essential oils, we mean it.",
        },
        {
          img: require("@/assets/cruelty_free.png"),
          title: "Cruelty-Free Commitment",
          text: "We never test our products on animals because we believe in wellness for all, including our furry friends.",
        },
        {
          img: require("@/assets/sustainability.png"),
          title: "Sustainability",
          text: "We are committed to sustainable practices, from sourcing our ingredients to eco-friendly packaging choices.",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style>
section {
  position: relative;
}
#homeParallax {
  background-image: url("~@/assets/bgScentology.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 600px;
  color: white;
}
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#homeSection {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}

@media (max-width: 1250px) {
  .home-content {
    background: linear-gradient(
      rgba(23, 61, 29, 0.414),
      rgba(23, 61, 29, 0.414)
    );
  }
}

@media only screen and (max-width: 768px) {
  #homeParallax {
    background-attachment: unset;
  }
}
</style>
